<template>
    <div class="content">
        <div class="top_bg" style="background-image: url('img/qixi/top_bg.png');">
            <div class="active_title" style="background-image: url('img/qixi/active_title.png');"> </div>
            <div class="active_time" style="background-image: url('img/qixi/active_time_bg.png');"> </div>
            <div @click="goRule" class="active_rule" style="background-image: url('img/qixi/w_rule.png');"> </div>
        </div>
        <div class="list_content">
            <div class="active_detail" style="background-image: url('img/qixi/active_detail.png');"></div>
            <div class="list">
                <div class="list_title" style="background-image: url('img/qixi/list_title.png');"></div>
                <div v-if="list.length > 0" class="list_box" style="background-image: url('img/qixi/list_box.png');">
                    <div v-for="(item, index) in list" :key="index" class="list_item"
                        style="background-image: url('img/qixi/love_value_bg.png');">
                        <div class="list_left">
                            <img class="face_img" :src="item.head_portrait" alt="">
                            <div class="face" style="background-image: url('img/qixi/top_other.png');">
                                <img v-if="index == 0" class="crown" src="img/qixi/crown.png" alt="">
                                <img class="index" :src="'img/qixi/' + (index + 1) + '.png'" alt="">
                                <div class="nickname">{{ item.nickname }}</div>
                            </div>
                            <div class="id">{{ 'ID:' + item.uid }}</div>
                        </div>
                        <div class="list_right">
                            <img class="love_title" src="img/qixi/love_value.png" alt="">
                            <div class="love_value">{{ item.total_value }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="list.length== 0" class="list_box_nodata"
                    style="background-image: url('img/qixi/list_box_nodata.png');">
                    <img class="no_data" src="img/noData.png" alt="">
                </div>
            </div>
            <div class="bottom_text">本活动最终解释权归多乐所有</div>
        </div>
        
    </div>
</template>
<script>
import "@/assets/css/qixiActive.css"
import { saintRank } from "@/api/api";
export default {
    data() {
        return {
            list: []
        }
    },
    created() {
        let access_token = this.$route.query.access_token
        localStorage.setItem('access_token', access_token)
        this.getRankData()
    },
    methods: {
        getRankData() {
            saintRank({}).then(res => {
                this.list = res.data.data
            })
        },
        goRule() {
            this.$router.push({ path: '/qixiRule', query: { } })
        },
    }
}
</script>